import React from "react"
import "./projects-gallery.scss"

import ProjectCard from "./ProjectCard"
import projects from "../data/projects"

// useEffect(() => {
//   const cmsURL = 'http://wp.dmills.uk/' 
//   const endpoint = 'wp-json/wp/v2/photo?per_page=100';

//   fetch( cmsURL + endpoint, {
//       method: 'GET'
//   })
//   .then(res => res.json() )
//   .then(posts => {
//       console.log( posts )
//       setPhotos( posts)
//   })
//   .catch( err => {
//       console.errror( err)
//   })
// }, []);



function ProjectsGallery() {
  return(
    <div className="projects-gallery">
      {
        projects.map(project => {
          return (
            <ProjectCard
              key={project.id}
              title={project.title}
              tagline={project.tagline}
              description={project.description}
              github={project.github}
              live={project.live}
            />
          )
        })
      }
    </div>
  )
}

export default ProjectsGallery
