import React from "react"
import ProjectsGallery  from "./components/ProjectsGallery"

import "./portfolio.scss"

function Portfolio() {
  return(
    <div className="portfolio-container" id="portfolio-container">
      <div className="page-description">
        <p>Here's a sample of some of my projects, hopefully you can get a feel for my work and the languages and tools I have experience with.</p>
        <p>Visit my <a target="_blank" rel="noopener noreferrer" href="https://github.com/dmills-webdev"><strong>github profile</strong></a> to see what else I've been playing about with recently!</p>

      </div>
      <ProjectsGallery />
    </div>
  )
}

export default Portfolio
