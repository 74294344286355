import React from "react"

import "./about.scss"

function About() {
  return(
    <div id="about-page">
      
      <h2>I am a full stack developer in Sheffield, England with 3+ years of experience.</h2>
        
      <p>
        I landed my first role at a small agency in 2021 and since then I've worked mainly on ecommerce projects, creating and enhancing WooCommerce and Shopify sites for companies 
        across the UK and beyond. 
      </p>

      <p>
        In my latest role I've also had the chance to work on some exciting wider ranging projects like modernising a vast custom PHP based adventure 
        touring site or using Cloudflare Workers to create a 2 way API integration between a client's ROS and CRM systems. 
      </p>

      <p>
        My focus is creating highly functional, efficient, secure and 
        accessible websites and tools that are well documented and easy to maintain.
      </p>

      <p>
        Outside of my professional experience,
        as a hobbyist I've been particularly interested in creating single page web applications and tools (using a MERN stack) as 
        well as exploring public APIs and data scraping projects. 
      </p>

        <div className="techtools">
          <div className="aligned-ul">
            <h2>My Technologies</h2>
            <ul>
              <li>JavaScript</li>
              <li>PHP</li>
              <li>React</li>
              <li>Node.js/Express.js</li>
              <li>Liquid/Twig</li>
              <li>HTML5</li>
              <li>SCSS</li>
            </ul>
          </div>
          <div className="aligned-ul">
            <h2>My Tools</h2>
            <ul>
              <li>Git</li>
              <li>npm</li>
              <li>MySQL</li>
              <li>Jest (unit testing)</li>
              <li>CLI basics</li>
              <li>Stack Overflow...</li>
            </ul>
          </div>
        </div>

        <p>And when I'm not coding I also enjoy running, bouldering, wildcamping and photography, just to name a few things!</p>

    </div>
  )
}

export default About
