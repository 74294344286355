const projects = [
  {
    id: 0,
    title:       "Rails of Sheffield",
    tagline:     "an ecommerce site",
    description: "Client Shopify store featuring a custom theme (based on Dawn) with lots of custom JS for the wishlist and preorder systems.",
    live:        "https://www.railsofsheffield.com/",
    github:      null
  },
  {
    id: 1,
    title:       "Building Feasts",
    tagline:     "a recipe, blogging and ecommerce site",
    description: "Client website built from scratch in WordPress using mainly blocks with custom templates and WooCommerce for the shop.",
    live:        "https://www.buildingfeasts.com/",
    github:      null
  },
  {
    id: 2,
    title:       "Birmingham Tin Box",
    tagline:     "an ecommerce site",
    description: "Client website built from scratch in WordPress using a custom theme and WooCommerce for the shop.",
    live:        "https://www.paint-tin.co.uk/",
    github:      null
  },
  {
    id: 3,
    title:       "Broadley James",
    tagline:     "a business, catalogue and support site",
    description: "Client website for which I was the primary maintainer and developer, again based in WordPress/WooCommerce.",
    live:        "https://www.broadleyjames.com/",
    github:      null
  },
  {
    id: 4,
    title:       "Lister",
    tagline:     "a todo list web-app",
    description: "Lister is a classic checklist/todo app made with React and Express.js. Users can create an account and log in so their lists persist between devices",
    live:        "https://lister-dmwd.netlify.app/",
    github:      "https://github.com/dmills-webdev/lister-backend"
  },
  {
    id: 5,
    title:       "Librio",
    tagline:     "a book cataloguing web-app",
    description: "Librio is an web app created with React. It allows users to catalogue their personal book libraries.",
    live:        "https://dmills-webdev.github.io/librio-react/",
    github:      "https://github.com/dmills-webdev/librio-react"
  },
  {
    id: 6,
    title:       "Calcio",
    tagline:     "a calculator web-app",
    description: "Calcio is a mathematical calculator app written in vanilla JavaScript.",
    live:        "https://dmills-webdev.github.io/calcio-2/",
    github:      "https://github.com/dmills-webdev/calculator"
  },
  {
    id: 7,
    title:       "TrickyTyper",
    tagline:     "a speed typing browser game",
    description: "TrickyTyper is a speed typing game created with React. The tests use a randomly generated wordlist from a larger word library each game while the metrics display shows statistics.",
    live:        "https://dmills-webdev.github.io/tricky-typer/",
    github:      "https://github.com/dmills-webdev/tricky-typer"
  },
  {
    id: 8,
    title:       "IMDBetter",
    tagline:     "a chrome extension",
    description: "IMDBetter is a very serious chrome extension (not!) that rescores films/shows on IMDB without 10s or 1s being taken into account. It connects to a simple Express/MongoDB backend that caches modified scores.",
    live:        "https://github.com/dmills-webdev/imdbetter-extenstion",
    github:      "https://github.com/dmills-webdev/imdbetter-extenstion"
  },
]

export default projects
