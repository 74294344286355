console.log('BEEP!!!!')

document.addEventListener('scroll', event => {

    console.log('SCROLLBEEP!!!!!!')
    const {scrollHeight, scrollTop, clientHeight} = event.target;
  
    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
        console.log('scrolled');
    }
});